import { KNOWLEDGE_BASE } from '../constants';
import { apiSlice } from './apiSlice';

export const knowledgeBaseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKnowledgeBase: builder.query({
      query: () => ({
        url: KNOWLEDGE_BASE
      }),
      keepUnusedDataFor: 5,
      providesTags: ['KnowledgeBase'],
    }),
    getKnowledgeBaseDetails: builder.query({
      query: (knowledgeBaseId) => ({
        url: `${KNOWLEDGE_BASE}/${knowledgeBaseId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createKnowledgeBase: builder.mutation({
      query: () => ({
        url: `${KNOWLEDGE_BASE}`,
        method: 'POST',
      }),
      invalidatesTags: ['KnowledgeBase'],
    }),
    updateKnowledgeBase: builder.mutation({
      query: (data) => ({
        url: `${KNOWLEDGE_BASE}/${data.knowledgeBaseId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['KnowledgeBase'],
    }),
    uploadKnowledgeBaseImage: builder.mutation({
      query: (data) => ({
        url: `/api/upload`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteKnowledgeBase: builder.mutation({
      query: (knowledgeBaseId) => ({
        url: `${KNOWLEDGE_BASE}/${knowledgeBaseId}`,
        method: 'DELETE',
      }),
      providesTags: ['KnowledgeBase'],
    }),
  }),
});

export const {
  useGetKnowledgeBaseQuery,
  useGetKnowledgeBaseDetailsQuery,
  useCreateKnowledgeBaseMutation,
  useUpdateKnowledgeBaseMutation,
  useUploadKnowledgeBaseImageMutation,
  useDeleteKnowledgeBaseMutation,
} = knowledgeBaseApiSlice;
