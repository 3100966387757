import { useState } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useContactMutation } from '../slices/contactApiSlice';
import { validateEmail } from './RegisterScreen';
import { toast } from 'react-toastify';

const NewContactScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [heading, setHeading] = useState('');
  const [text, setText] = useState('');
  const [contact, { isLoading, error }] = useContactMutation();
  const resetState = () => {
    setName('');
    setEmail('');
    setHeading('');
    setText('');
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      toast.error('Email nije u dobrom formatu');
    } else {
      try {
        await contact({
          name,
          email,
          heading,
          text,
        }).unwrap();
        resetState();
        toast.success('Poruka je uspešno poslata.');
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>{error.data.message}</Message>;
  return (
    <>
      <Breadcrumb />
      <div className='container-fluid'>
        <h2 className='section-title position-relative text-uppercase mx-xl-5 mb-4'>
          <span className='bg-secondary pr-3'>Kontaktirajte nas</span>
        </h2>
        <div className='row px-xl-5'>
          <div className='col-lg-7 mb-5'>
            <div className='contact-form bg-light p-30'>
              <div id='success' />
              <form
                name='sentMessage'
                id='contactForm'
                noValidate='novalidate'
                onSubmit={submitHandler}
              >
                <div className='control-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Vase ime'
                    required='required'
                    data-validation-required-message='Molimo Vas unesite vase ime'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className='help-block text-danger' />
                </div>
                <div className='control-group'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Vas email'
                    required='required'
                    data-validation-required-message='Molimo Vas unesite vas email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className='help-block text-danger' />
                </div>
                <div className='control-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='subject'
                    placeholder='Naslov poruke'
                    required='required'
                    data-validation-required-message='Molimo Vas unesite naslov poruke'
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                  />
                  <p className='help-block text-danger' />
                </div>
                <div className='control-group'>
                  <textarea
                    className='form-control'
                    rows={8}
                    id='message'
                    placeholder='Tekst poruke'
                    required='required'
                    data-validation-required-message='Molimo Vas unesite tekst poruke'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                  <p className='help-block text-danger' />
                </div>
                <div>
                  <button
                    className='btn btn-primary py-2 px-4'
                    type='submit'
                    id='sendMessageButton'
                  >
                    Posalji poruku
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className='col-lg-5 mb-5'>
            <div className='bg-light p-30 mb-30'>
              <iframe
                title='Mapa'
                style={{ width: '100%', height: '250px' }}
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91814.04023902015!2d20.90683225!3d44.01749375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475720dbdee00fd9%3A0xdfa77784524b968!2z0JrRgNCw0LPRg9GY0LXQstCw0YY!5e0!3m2!1ssr!2srs!4v1702332772021!5m2!1ssr!2srs'
                frameBorder={0}
                allowFullScreen
                aria-hidden='false'
                tabIndex={0}
              />
            </div>
            <div className='bg-light p-30 mb-3'>
              <p className='mb-2'>
                <i className='fa fa-map-marker-alt text-primary mr-3' />
                Ace Stojanovica 12, Kragujevac, Srbija
              </p>
              <p className='mb-2'>
                <i className='fa fa-envelope text-primary mr-3' />
                info@5S.rs
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewContactScreen;
