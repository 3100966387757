import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { resetCart } from '../slices/cartSlice';
import { useLogoutMutation } from '../slices/usersApiSlice';
import categories from '../resources/categories.json';
import subcategories from '../resources/subcategories.json';
import { useState } from 'react';
import { useGetKnowledgeBaseQuery } from '../slices/knowledgeBaseApiSlice';
/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();
  const { data, isLoading, error, refetch } = useGetKnowledgeBaseQuery();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      // NOTE: here we need to reset cart state for when a user logs out so the next
      // user doesn't inherit the previous users cart and shipping
      dispatch(resetCart());
      navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };

  // FIX: uncontrolled input - urlKeyword may be undefined
  const [keyword, setKeyword] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword) {
      navigate(`/search/${keyword.trim()}`);
      setKeyword('');
    } else {
      navigate('/');
    }
  };

  return (
    <header>
      <div className='container-fluid'>
        {' '}
        <div className='row bg-secondary py-1 px-xl-5'>
          <div className='col-lg-6 d-none d-lg-block'>
            <div className='d-inline-flex align-items-center h-100'>
              <Link className='text-body mr-3' to={'/'}>
                Početna
              </Link>
              <Link className='text-body mr-3' to={'/products'}>
                Proizvodi
              </Link>
              <Link className='text-body mr-3' to={'/contact'}>
                Kontakt
              </Link>
              <Link className='text-body mr-3' to={'/cart'}>
                Korpa
              </Link>
            </div>
          </div>
          <div className='col-lg-6 text-center text-lg-right'>
            <div className='d-inline-flex align-items-center'>
              <div className='btn-group'>
                <button
                  type='button'
                  className='btn btn-sm btn-light dropdown-toggle'
                  data-toggle='dropdown'
                >
                  Moj nalog
                </button>
                <div className='dropdown-menu dropdown-menu-right'>
                  {userInfo ? (
                    userInfo.isAdmin ? (
                      <>
                        <Link to='/admin/productlist' className='dropdown-item'>
                          Proizvodi
                        </Link>
                        <Link to='/admin/orderlist' className='dropdown-item'>
                          Porudzbine
                        </Link>
                        <Link to='/admin/userlist' className='dropdown-item'>
                          Korisnici
                        </Link>
                        <Link
                          to='/admin/knowledgeBaseList'
                          className='dropdown-item'
                        >
                          Baza znanja
                        </Link>
                        <Link to='/profile' className='dropdown-item'>
                          Profil
                        </Link>
                        <button
                          onClick={logoutHandler}
                          className='dropdown-item'
                        >
                          Izloguj se
                        </button>
                      </>
                    ) : (
                      <>
                        <Link to='/profile' className='dropdown-item'>
                          Profil
                        </Link>
                        <Link
                          to='#'
                          onClick={logoutHandler}
                          className='dropdown-item'
                        >
                          Izloguj se
                        </Link>
                      </>
                    )
                  ) : (
                    <Link to='/login' className='dropdown-item'>
                      Uloguj se
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className='d-inline-flex align-items-center d-block d-lg-none'>
              <Link to='/cart' className='btn px-0 ml-2'>
                <i className='fas fa-shopping-cart text-dark'></i>
                <span className='badge text-dark border border-dark rounded-circle'>
                  {cartItems.length}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className='row align-items-center bg-light py-3 px-xl-5 d-none d-lg-flex'>
          <div className='col-lg-4'>
            <Link to='/' className='text-decoration-none'>
              <img src='img/logo.png' alt='logo' style={{ width: '100px' }} />
            </Link>
          </div>
          <div className='col-lg-4 col-6 text-left'>
            <form>
              <div className='input-group'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Pretraži proizvode'
                  onChange={(event) => setKeyword(event.target.value)}
                  value={keyword}
                />
                <div className='input-group-append'>
                  <span
                    className='input-group-text bg-transparent text-primary'
                    onClick={submitHandler}
                  >
                    <i className='fa fa-search' />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className='col-lg-4 col-6 text-right'>
            <p className='m-0'>Treba Vam pomoc?</p>
            <h5 className='m-0'>
              <a href='mailto:info@5S.rs'>info@5S.rs</a>
            </h5>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <div className='container-fluid bg-dark mb-30'>
        <div className='row px-xl-5'>
          <div className='col-lg-3 d-none d-lg-block'>
            <a
              className='btn d-flex align-items-center justify-content-between bg-primary w-100'
              data-toggle='collapse'
              href='#navbar-vertical'
              style={{ height: '65px', padding: '0 30px' }}
              onMouseEnter={() => setCollapsed(false)}
            >
              <h6 className='text-dark m-0'>
                <i className='fa fa-bars mr-2' />
                Kategorije
              </h6>
              <i className='fa fa-angle-down text-dark' />
            </a>
            <nav
              className='collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light'
              id='navbar-vertical'
              style={{
                width: 'calc(100% - 30px)',
                zIndex: 999,
                display: collapsed ? 'none' : '',
              }}
            >
              <div className='navbar-nav w-100'>
                {categories.map((category, index) => {
                  return (
                    <div className='nav-item dropdown dropright' key={index}>
                      <a
                        href='#'
                        className='nav-link dropdown-toggle'
                        data-toggle='dropdown'
                      >
                        {category.displayName}{' '}
                        <i className='fa fa-angle-right float-right mt-1'></i>
                      </a>
                      <div className='dropdown-menu position-absolute rounded-0 border-0 m-0'>
                        {subcategories[category.name].map(
                          (subcategory, index) => {
                            return (
                              <Link
                                to={`/subcategory/${subcategory.name.toLowerCase()}`}
                                className='dropdown-item'
                                key={subcategory.name + index}
                                onClick={() => setCollapsed(true)}
                              >
                                {subcategory.displayName}{' '}
                              </Link>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </nav>
          </div>
          <div className='col-lg-9'>
            <nav className='navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0'>
              <Link to='/' className='text-decoration-none d-block d-lg-none'>
                <img src='img/logo.png' alt='logo' style={{ width: '100px' }} />{' '}
              </Link>
              <button
                type='button'
                className='navbar-toggler'
                data-toggle='collapse'
                data-target='#navbarCollapse'
              >
                <span className='navbar-toggler-icon' />
              </button>
              <div
                className='collapse navbar-collapse justify-content-between'
                id='navbarCollapse'
              >
                <div className='navbar-nav mr-auto py-0'>
                  <Link to='/' className='nav-item nav-link active'>
                    Početna
                  </Link>
                  <Link to='/products' className='nav-item nav-link'>
                    Proizvodi
                  </Link>
                  <div className='nav-item dropdown'>
                    <a
                      href='#'
                      className='nav-link dropdown-toggle'
                      data-toggle='dropdown'
                    >
                      Baza znanja &nbsp;
                      <i className='fa fa-angle-down mt-1' />
                    </a>
                    {data && data.knowledgeBase.length > 0 && (
                      <div className='dropdown-menu bg-primary rounded-0 border-0 m-0'>
                        {data.knowledgeBase.map((item, key) => {
                          return (
                            <Link
                              key={item._id + key}
                              to={`/knowledge-base/${item._id}`}
                              className='dropdown-item'
                            >
                              {item.title}
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <Link to='/contact' className='nav-item nav-link'>
                    Kontakt
                  </Link>
                </div>
                <div className='navbar-nav ml-auto py-0 d-none d-lg-block'>
                  <Link to='/cart' href className='btn px-0 ml-3'>
                    <i className='fas fa-shopping-cart text-primary' />
                    <span
                      className='badge text-secondary border border-secondary rounded-circle'
                      style={{ paddingBottom: '2px' }}
                    >
                      {cartItems.length}
                    </span>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
