import { useNavigate } from 'react-router-dom';
import { useGetOrdersQuery } from '../../slices/ordersApiSlice';

const AdminNewOrderListScreen = () => {
  const navigate = useNavigate();
  const { data: orders, isLoading, error } = useGetOrdersQuery();

  if (isLoading) return null;
  if (error) return null;
  if (orders.length < 1) return null;

  return (
    <>
      <div className='container-fluid'>
        <div className='row px-xl-5'>
          <div className='col-lg-12 table-responsive'>
            <h2 className='section-title position-relative text-uppercase mb-4'>
              <span className='bg-secondary pr-3'>Porudzbine</span>
            </h2>
          </div>
          <div className='col-lg-12 table-responsive mb-5'>
            <table className='table table-light table-borderless table-hover text-center mb-0'>
              <thead className='thead-dark'>
                <tr>
                  <th>Korisnik</th>
                  <th>Datum</th>
                  <th>Plaćeno</th>
                  <th>Dostavljeno</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='align-middle'>
                {orders.length > 1 &&
                  orders.map((order) => (
                    <tr>
                      <td className='align-middle'>
                        <img
                          src='img/product-1.jpg'
                          alt=''
                          style={{ width: '50px' }}
                        />{' '}
                        {order.user && order.user.name}
                      </td>
                      <td className='align-middle'>
                        {order.createdAt.substring(0, 10)}
                      </td>
                      <td className='align-middle'>
                        {order.isPaid ? (
                          order.paidAt.substring(0, 10)
                        ) : (
                          <i className='fa fa-times' style={{ color: 'red' }} />
                        )}
                      </td>
                      <td className='align-middle'>
                        {order.isDelivered ? (
                          order.paidAt.substring(0, 10)
                        ) : (
                          <i className='fa fa-times' style={{ color: 'red' }} />
                        )}
                      </td>
                      <td className='align-middle'>
                        <button
                          className='btn btn-sm btn-info'
                          onClick={() => navigate(`/order/${order._id}`)}
                        >
                          Detalji
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <Paginate pages={data.pages} page={data.page} isAdmin={true} /> */}
    </>
  );
};

export default AdminNewOrderListScreen;
