import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { resetCart } from '../slices/cartSlice';
import { useCreateOrderMutation } from '../slices/ordersApiSlice';

const CheckoutScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.auth.userInfo);
  const [createOrder, { isLoading, error }] = useCreateOrderMutation();

  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');

  const placeOrderHandler = async () => {
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: {
          address: street,
          city,
          postalCode: postCode,
        },
        paymentMethod: 'CashOnDelivery',
        itemsPrice: cart.itemsPrice,
        shippingPrice: 'Free',
        taxPrice: 0,
        totalPrice: cart.totalPrice,
      }).unwrap();
      dispatch(resetCart());
      navigate(`/order/${res._id}`);
    } catch (err) {
      toast.error(err);
    }
  };

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>{error.data.message}</Message>;

  return (
    <div className='container-fluid'>
      <div className='row px-xl-5'>
        <div className='col-lg-8'>
          <h5 className='section-title position-relative text-uppercase mb-3'>
            <span className='bg-secondary pr-3'>Adresa za dostavu</span>
          </h5>
          <div className='bg-light p-30 mb-5'>
            <div className='row'>
              <div className='col-md-12 form-group'>
                <label>Ime firme</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Petar'
                  disabled
                  value={user.name}
                />
              </div>
              <div className='col-md-6 form-group'>
                <label>E-mail</label>
                <input
                  className='form-control'
                  type='text'
                  value={user.email}
                  disabled
                />
              </div>
              <div className='col-md-6 form-group'>
                <label>Broje telefona</label>
                <input
                  className='form-control'
                  type='text'
                  value={user.phone}
                  disabled
                />
              </div>
              <div className='col-md-6 form-group'>
                <label>Ulica</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Ulica bb'
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div className='col-md-6 form-group'>
                <label>Grad</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Beograd'
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className='col-md-6 form-group'>
                <label>Postanski broj</label>
                <input
                  className='form-control'
                  type='text'
                  placeholder={123}
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
          <h5 className='section-title position-relative text-uppercase mb-3'>
            <span className='bg-secondary pr-3'>Detalji porudzbine</span>
          </h5>
          <div className='bg-light p-30 mb-5'>
            <div className='border-bottom'>
              <h6 className='mb-3'>Proizvodi</h6>
              {cart.cartItems.map((product, i) => {
                return (
                  <div
                    className='d-flex justify-content-between'
                    key={product._id}
                  >
                    <p>{product.name}</p>
                    <p>{product.qty} komada</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='mb-5'>
            <h5 className='section-title position-relative text-uppercase mb-3'>
              <span className='bg-secondary pr-3'>Plaćanje</span>
            </h5>
            <div className='bg-light p-30'>
              <div className='form-group'>
                <div className='custom-control custom-radio'>
                  <input
                    type='radio'
                    className='custom-control-input'
                    name='payment'
                    id='paypal'
                    defaultChecked={true}
                  />
                  <label className='custom-control-label' htmlFor='paypal'>
                    Placanje pouzećem
                  </label>
                </div>
              </div>
              <button
                className='btn btn-block btn-primary font-weight-bold py-3'
                onClick={placeOrderHandler}
              >
                Posalji porudzbinu
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutScreen;
