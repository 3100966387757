import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useGetKnowledgeBaseDetailsQuery } from '../slices/knowledgeBaseApiSlice';

const KnowledgeBase = () => {
  const { id } = useParams();
  const {
    data: knowledgeBase,
    isLoading,
    error,
  } = useGetKnowledgeBaseDetailsQuery(id);
  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>{error.data.message}</Message>;

  return (
    <>
      <div className='container-fluid'>
        <div className='row px-xl-5'>
          <div className='col-12'>
            <nav className='breadcrumb bg-light mb-30'>
              <Link className='breadcrumb-item text-dark' to='/'>
                Početna
              </Link>
              <Link className='breadcrumb-item text-dark' to='/products'>
                Proizvodi
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className='container-fluid pb-5'>
        <div className='row px-xl-5'>
          <div className='col-lg-4 mb-30'>
            <div
              id='product-carousel'
              className='carousel slide'
              data-ride='carousel'
            >
              <div className='carousel-inner bg-light'>
                {knowledgeBase.images.map((image, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                    key={knowledgeBase._id}
                  >
                    <img className='w-100 h-100' src={image} alt='Image' />
                  </div>
                ))}
              </div>
              <a
                className='carousel-control-prev'
                href='#product-carousel'
                data-slide='prev'
              >
                <i className='fa fa-2x fa-angle-left text-dark'></i>
              </a>
              <a
                className='carousel-control-next'
                href='#product-carousel'
                data-slide='next'
              >
                <i className='fa fa-2x fa-angle-right text-dark'></i>
              </a>
            </div>
          </div>

          <div className='col-lg-8 h-auto mb-30'>
            <div className='h-100 bg-light p-30'>
              <h1>{knowledgeBase.title}</h1>
              <div>
                {knowledgeBase.description.indexOf('</') !== -1 ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: knowledgeBase.description.replace(
                        /(<? *script)/gi,
                        'illegalscript'
                      ),
                    }}
                  ></div>
                ) : (
                  knowledgeBase.description
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowledgeBase;
