import { Link } from 'react-router-dom';
import categories from '../resources/categories.json';

const Categories = () => {
  return (
    <div className='container-fluid pt-5'>
      <h2 className='section-title position-relative text-uppercase mx-xl-5 mb-4'>
        <span className='bg-secondary pr-3'>Kategorije</span>
      </h2>
      <div className='row px-xl-5 pb-3'>
        {categories.map((category, index) => {
          return (
            <div
              className='col-lg-3 col-md-4 col-sm-6 pb-1'
              key={category.name + index}
            >
              <Link
                className='text-decoration-none'
                to={`category/${category.name}`}
              >
                <div className='cat-item d-flex align-items-center mb-4'>
                  <div
                    className='overflow-hidden'
                    style={{ width: 100, height: 100 }}
                  >
                    <img className='img-fluid' src={category.img} alt='' />
                  </div>
                  <div className='flex-fill pl-3'>
                    <h6>{category.displayName}</h6>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Categories;
