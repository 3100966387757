import { Link } from 'react-router-dom';

const Partners = () => {
  return (
    <div className='container-fluid pt-5 pb-3'>
      <h2 className='section-title position-relative text-uppercase mx-xl-5 mb-4'>
        <span className='bg-secondary pr-3'>Partneri</span>
      </h2>
      <div className='row px-xl-5'>
        <div className='col-lg-4 col-md-6 col-sm-6 pb-1'>
          <div className='product-item bg-light mb-4'>
            <div className='product-img position-relative overflow-hidden'>
              <img
                className='img-fluid w-100'
                src='../images/LeanUpAkademija.png'
                alt=''
              />
                            <div className='product-action'>
                <Link
                  className='btn btn-outline-dark btn-square'
                  to='https://www.leanupakademija.com/'
                >
                  <i className='fa fa-link' />
                </Link>
              </div>
              <Link
                className='btn btn-outline-dark btn-square'
                style={{ visibility: "hidden" }}
                to='https://www.leanupakademija.com/'
              ></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partners;
