import { Button, Col, Image, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import {
  useDeliverOrderMutation,
  useGetOrderDetailsQuery
} from '../slices/ordersApiSlice';

const OrderScreen = () => {
  const { id: orderId } = useParams();

  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);

  const [deliverOrder] = useDeliverOrderMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const deliverHandler = async () => {
    await deliverOrder(orderId);
    refetch();
  };

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>{error.data.message}</Message>;
  return (
    <>
      <div className='container-fluid'>
        <div className='row px-xl-5'>
          <div className='col-lg-12'>
            <h5 className='section-title position-relative text-uppercase mb-3'>
              <span className='bg-secondary pr-3'>
                Porudzbina broj: {order._id}
              </span>
            </h5>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Proizvodi</h2>
                {order.orderItems.length === 0 ? (
                  <Message>Porudzbina je prazna.</Message>
                ) : (
                  <ListGroup variant='flush'>
                    {order.orderItems.map((item, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col md={1}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Col>
                          <Col>
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </Col>
                          <Col md={4}>Komada = {item.qty}</Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                <h2>Podaci o adresi</h2>
                <p>
                  <strong>Ime korisnika: </strong> {order.user.name}
                </p>
                <p>
                  <strong>Email: </strong>{' '}
                  <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                </p>
                <p>
                  <strong>Adresa za dostavu: </strong>
                  {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                  {order.shippingAddress.postalCode},{' '}
                  {order.shippingAddress.country}
                </p>
                {order.isDelivered ? (
                  <Message variant='success'>
                    Dostaljeno {order.deliveredAt}
                  </Message>
                ) : (
                  <Message variant='danger'>Nije dostavljeno</Message>
                )}
              </ListGroup.Item>
              {userInfo && userInfo.isAdmin && !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type='button'
                    className='btn btn-block'
                    onClick={deliverHandler}
                  >
                    Porudzbina je dostavljena
                  </Button>
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderScreen;
