import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormContainer from '../../components/FormContainer';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import {
  useGetKnowledgeBaseDetailsQuery,
  useUpdateKnowledgeBaseMutation,
  useUploadKnowledgeBaseImageMutation,
} from '../../slices/knowledgeBaseApiSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const KnowledgeBaseEditScreen = () => {
  const { id: knowledgeBaseId } = useParams();

  const [title, setTitle] = useState('');
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [published, setPublished] = useState(false);
  const {
    data: knowledgeBase,
    isLoading,
    refetch,
    error,
  } = useGetKnowledgeBaseDetailsQuery(knowledgeBaseId);

  const [updateKnowledgeBase, { isLoading: loadingUpdate }] =
    useUpdateKnowledgeBaseMutation();

  const [uploadKnowledgeBaseImage, { isLoading: loadingUpload }] =
    useUploadKnowledgeBaseImageMutation();

  const navigate = useNavigate();
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    ['clean'], // remove formatting button
  ];

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateKnowledgeBase({
        knowledgeBaseId,
        images,
        title,
        description,
        published,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Knowledge base updated');
      refetch();
      navigate('/admin/knowledgeBaseList');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (knowledgeBase) {
      setTitle(knowledgeBase.title);
      setImages(knowledgeBase.images);
      setDescription(knowledgeBase.description);
      setPublished(knowledgeBase.published);
    }
  }, [knowledgeBase]);

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    const files = e.target.files;
    if (files.length !== 0) {
      for (const single_file of files) {
        formData.append('images', single_file);
      }
    }
    try {
      const res = await uploadKnowledgeBaseImage(formData).unwrap();
      toast.success(res.message);
      setImages(res.images);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Link to='/admin/knowledgeBaseList' className='btn btn-light my-3'>
        Nazad
      </Link>
      <FormContainer>
        <h1>Izmeni post baze znanja</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler} encType='multipart/form-data'>
            <Form.Group controlId='title'>
              <Form.Label>Naslov</Form.Label>
              <Form.Control
                type='text'
                placeholder='Unesite sifru proizvoda'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='image'>
              <Form.Label>Slika</Form.Label>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  margin: '0 auto',
                  maxWidth: 1000,
                }}
              >
                {images.map((image) => (
                  <div
                    style={{
                      flex: '1 1 100px',
                      margin: 10,
                    }}
                  >
                    <img
                      style={{ maxWidth: '100%', height: 'auto' }}
                      src={image}
                      alt={image}
                    />
                  </div>
                ))}
              </div>
              <Form.Control
                label='Izaberite sliku'
                onChange={uploadFileHandler}
                type='file'
                multiple
              ></Form.Control>
              {loadingUpload && <Loader />}
            </Form.Group>
            <Form.Group controlId='description'>
              <Form.Label>Tekst</Form.Label>
              <ReactQuill
                theme='snow'
                value={description}
                onChange={setDescription}
                modules={{ toolbar: toolbarOptions }}
              />
            </Form.Group>
            <Form.Group
              controlId='published'
              style={{ display: 'flex', marginTop: 10 }}
            >
              <Form.Label>Da li je post aktivan?</Form.Label>
              <Form.Control
                type='checkbox'
                checked={published}
                onChange={() => setPublished(!published)}
              ></Form.Control>
            </Form.Group>
            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Izmeni
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default KnowledgeBaseEditScreen;
