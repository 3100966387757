import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import Paginate from '../../components/Paginate';
import {
  useCreateKnowledgeBaseMutation,
  useDeleteKnowledgeBaseMutation,
  useGetKnowledgeBaseQuery,
} from '../../slices/knowledgeBaseApiSlice';

const KnowledgeBaseListScreen = () => {
  const navigate = useNavigate();
  const { pageNumber } = useParams();

  const { data, isLoading, error, refetch } = useGetKnowledgeBaseQuery({
    pageNumber,
  });

  const [deleteKnowledgeBase, { isLoading: loadingDelete }] =
    useDeleteKnowledgeBaseMutation();

  const deleteHandler = async (id) => {
    if (
      window.confirm(
        'Da li ste sigurni da želite da obrišete stranicu iz baze znanja?'
      )
    ) {
      try {
        await deleteKnowledgeBase(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [createKnowledgeBase, { isLoading: loadingCreate }] =
    useCreateKnowledgeBaseMutation();

  const createKnowledgeBaseHandler = async () => {
    try {
      await createKnowledgeBase();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <div className='container-fluid'>
            <div className='row px-xl-5'>
              <div className='col-lg-12 table-responsive mb-5'>
                <h2 className='section-title position-relative text-uppercase mb-4'>
                  <span className='bg-secondary pr-3'>Baza znanja</span>
                </h2>
              </div>
              <div className='col-lg-12 table-responsive mb-5'>
                <button
                  className='btn btn-sm btn-primary btn-plus'
                  onClick={createKnowledgeBaseHandler}
                >
                  Dodaj novi post u bazu znanja &nbsp;
                  <i className='fa fa-plus' />
                </button>
                <br />
                <br />
                <table className='table table-light table-borderless table-hover text-center mb-0'>
                  <thead className='thead-dark'>
                    <tr>
                      <th>Naziv proizvoda</th>
                      <th>Izmeni / Obrisi</th>
                    </tr>
                  </thead>
                  <tbody className='align-middle'>
                    {data.knowledgeBase.map((post) => (
                      <tr key={post._id}>
                        <td className='align-middle'>
                          {/* <img
                            src={`img/${product.image}`}
                            alt={product.name}
                            style={{ width: '50px' }}
                          />{' '} */}
                          {post.title}
                        </td>
                        <td className='align-middle'>
                          <button
                            onClick={() =>
                              navigate(`/admin/knowledgeBase/${post._id}/edit`)
                            }
                            className='btn btn-sm btn-info btn-plus'
                          >
                            <i className='fa fa-eraser' />
                          </button>
                          &nbsp;
                          <button
                            onClick={() => deleteHandler(post._id)}
                            className='btn btn-sm btn-danger'
                          >
                            <i className='fa fa-times' />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default KnowledgeBaseListScreen;
