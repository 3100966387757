import React from 'react';
import ReactDOM from 'react-dom/client';
// import './assets/styles/bootstrap.custom.css';
// import './assets/styles/index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import App from './App';
import AdminRoute from './components/AdminRoute';
import PrivateRoute from './components/PrivateRoute';
import reportWebVitals from './reportWebVitals';
import AdminNewOrderListScreen from './screens/admin/AdminNewOrderListScreen';
import AdminNewUserListScreen from './screens/admin/AdminNewUserListScreen';
import ProductListScreenAdmin from './screens/admin/ProductListScreenAdmin';
import ProductEditScreen from './screens/admin/ProductEditScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import KnowledgeBase from './screens/KnowledgeBase';
import LoginScreen from './screens/LoginScreen';
import CartScreen from './screens/CartScreen';
import NewContactScreen from './screens/NewContactScreen';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import OrderScreen from './screens/OrderScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProfileScreen from './screens/ProfileScreen';
import RegisterScreen from './screens/RegisterScreen';
import store from './store';
import KnowledgeBaseListScreen from './screens/admin/KnowledgeBaseListScreen';
import KnowledgeBaseEditScreen from './screens/admin/KnowledgeBaseEditScreen';
import SubcategoryListScreen from './screens/SubcategoryListScreen';
import ProductSubcategoryScreen from './screens/ProductSubcategoryScreen';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/products' element={<ProductSubcategoryScreen />} />
      <Route path='/search/:keyword' element={<ProductListScreen />} />
      <Route path='/page/:pageNumber' element={<ProductListScreen />} />
      <Route
        path='/search/:keyword/page/:pageNumber'
        element={<ProductListScreen />}
      />
      <Route path='/category/:category' element={<SubcategoryListScreen />} />
      <Route path='/subcategory/:subcategory' element={<ProductListScreen />} />
      <Route path='/product/:id' element={<ProductScreen />} />
      <Route path='/knowledge-base/:id' element={<KnowledgeBase />} />
      <Route path='/cart' element={<CartScreen />} />
      <Route path='/contact' element={<NewContactScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />
      {/* Registered users */}
      <Route path='' element={<PrivateRoute />}>
        <Route path='/checkout' element={<CheckoutScreen />} />
        <Route path='/order/:id' element={<OrderScreen />} />
        <Route path='/profile' element={<ProfileScreen />} />
      </Route>
      {/* Admin users */}
      <Route path='' element={<AdminRoute />}>
        <Route path='/admin/orderlist' element={<AdminNewOrderListScreen />} />
        <Route path='/admin/productlist' element={<ProductListScreenAdmin />} />
        <Route path='/admin/userlist' element={<AdminNewUserListScreen />} />
        <Route path='/admin/product/:id/edit' element={<ProductEditScreen />} />
        <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
        <Route
          path='/admin/knowledgeBaseList'
          element={<KnowledgeBaseListScreen />}
        />
        <Route
          path='/admin/knowledgeBase/:id/edit'
          element={<KnowledgeBaseEditScreen />}
        />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
