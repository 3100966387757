import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import Message from '../components/Message';
import NewProduct from '../components/NewProduct';
import Paginate from '../components/Paginate';
import { useGetProductsQuery } from '../slices/productsApiSlice';

const ProductListScreen = () => {
  const { pageNumber, keyword, category, subcategory } = useParams();

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>{error.data.message}</Message>;
  const { products, page, pages } = data;
  let filteredProducts = products.filter(
    (product) => product.published === true
  );

  if (category) {
    filteredProducts = filteredProducts.filter((product) => {
      return product.category.toLowerCase() === category;
    });
  }
  if (subcategory) {
    filteredProducts = filteredProducts.filter((product) => {
      return product.subcategory === subcategory;
    });
  }

  const hasProducts = filteredProducts.length > 0;

  return (
    <>
      <Breadcrumb />
      <div className='container-fluid'>
        <div className='row px-xl-5'>
          {/* Shop Product Start */}
          <div className='col-lg-12'>
            <div className='row pb-3'>
              {filteredProducts.map((product) => (
                <NewProduct key={product._id} product={product} />
              ))}
              {!hasProducts && (
                <div className='col-lg-12'>
                  <div className='product-item bg-light mb-4'>
                    <div className='text-center py-4 text-truncate'>
                      Trenutno nema proizvoda
                    </div>
                  </div>
                </div>
              )}
              <Paginate
                pages={pages}
                page={page}
                keyword={keyword ? keyword : ''}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductListScreen;
