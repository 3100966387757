import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { addToCart } from '../slices/cartSlice';
import { useGetProductDetailsQuery } from '../slices/productsApiSlice';

const ProductScreen = () => {
  const { id: productId } = useParams();

  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);

  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty }));
    toast.success(`Uspesno ste dodali proizvod u korpu: ${product.name} `);
  };

  const {
    data: product,
    isLoading,
    error,
  } = useGetProductDetailsQuery(productId);
  if (isLoading) return <Loader />;
  if (error) return <Message variant='danger'>{error.data.message}</Message>;

  return (
    <>
      <>
        {/* Breadcrumb Start */}
        <div className='container-fluid'>
          <div className='row px-xl-5'>
            <div className='col-12'>
              <nav className='breadcrumb bg-light mb-30'>
                <Link className='breadcrumb-item text-dark' to='/'>
                  Početna
                </Link>
                <Link className='breadcrumb-item text-dark' to='/products'>
                  Proizvodi
                </Link>
                <span className='breadcrumb-item active'>{product.name}</span>
              </nav>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* Shop Detail Start */}
        <div className='container-fluid pb-5'>
          <div className='row px-xl-5'>
            <div className='col-lg-5 mb-30'>
              <div
                id='product-carousel'
                className='carousel slide'
                data-ride='carousel'
              >
                <div className='carousel-inner bg-light'>
                  {product.images.map((image, index) => (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={product._id + index}>
                      <img className='w-100 h-100' src={image} alt='' />
                    </div>
                  ))}
                </div>
                <a
                  className='carousel-control-prev'
                  href='#product-carousel'
                  data-slide='prev'
                >
                  <i className='fa fa-2x fa-angle-left text-dark'></i>
                </a>
                <a
                  className='carousel-control-next'
                  href='#product-carousel'
                  data-slide='next'
                >
                  <i className='fa fa-2x fa-angle-right text-dark'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-7 h-auto mb-30'>
              <div className='h-100 bg-light p-30'>
                <h3>{product.name}</h3>
                {product.description.indexOf('</') !== -1 ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.description.replace(
                        /(<? *script)/gi,
                        'illegalscript'
                      ),
                    }}
                  ></div>
                ) : (
                  product.description
                )}
                <div className='d-flex align-items-center mb-4 pt-2'>
                  <div
                    className='input-group quantity mr-3'
                    style={{ width: '130px' }}
                  >
                    <div className='input-group-btn'>
                      <button
                        className='btn btn-primary btn-minus'
                        onClick={() => setQty(qty - 1)}
                      >
                        <i className='fa fa-minus' />
                      </button>
                    </div>
                    <input
                      type='text'
                      className='form-control bg-secondary border-0 text-center'
                      value={qty}
                      readOnly
                    />
                    <div className='input-group-btn'>
                      <button
                        className='btn btn-primary btn-plus'
                        onClick={() => setQty(qty + 1)}
                      >
                        <i className='fa fa-plus' />
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={addToCartHandler}
                    className='btn btn-primary px-3'
                  >
                    <i className='fa fa-shopping-cart mr-1' /> Dodaj u korpu
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Shop Detail End */}
      </>
    </>
  );
};

export default ProductScreen;
