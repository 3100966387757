import { Link } from 'react-router-dom';
/* eslint-disable jsx-a11y/anchor-is-valid */
const NewFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className='container-fluid bg-dark text-secondary mt-5 pt-5'>
        <div className='row px-xl-5 pt-5'>
          <div className='col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5'>
            <h5 className='text-secondary text-uppercase mb-4'>
              Ostanimo u kontaktu
            </h5>
            <p className='mb-2'>
              <i className='fa fa-map-marker-alt text-primary mr-3' />
              Ace Stojanovica 12, Kragujevac, Srbija
            </p>
            <p className='mb-2'>
              <i className='fa fa-envelope text-primary mr-3' />
              <a href="mailto:info@5S.rs">info@5S.rs</a>
            </p>
          </div>
          <div className='col-lg-8 col-md-12'>
            <div className='row'>
              <div className='col-md-6 mb-5'>
                <h5 className='text-secondary text-uppercase mb-4'>
                  Brzi linkovi
                </h5>
                <div className='d-flex flex-column justify-content-start'>
                  <Link className='text-secondary mb-2' to={'/'}>
                    <i className='fa fa-angle-right mr-2' />
                    Početna
                  </Link>
                  <Link className='text-secondary mb-2' to={'/products'}>
                    <i className='fa fa-angle-right mr-2' />
                    Proizvodi
                  </Link>
                  <Link className='text-secondary mb-2' to={'/contact'}>
                    <i className='fa fa-angle-right mr-2' />
                    Kontakt
                  </Link>
                  <Link className='text-secondary mb-2' to={'/cart'}>
                    <i className='fa fa-angle-right mr-2' />
                    Korpa
                  </Link>
                </div>
              </div>
              <div className='col-md-6 mb-5'>
                <h6 className='text-secondary text-uppercase'>Pratite nas</h6>
                <div className='d-flex'>
                  <Link
                    className='btn btn-primary btn-square mr-2'
                    to={'https://www.linkedin.com/company/inntech-group/'}
                  >
                    <i className='fab fa-linkedin-in' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='row border-top mx-xl-5 py-4'
          style={{ borderColor: 'rgba(256, 256, 256, .1) !important' }}
        >
          <div className='col-md-12 px-xl-0'>
            <p className='mb-md-0 text-center text-md-left text-secondary'>
              ©{' '}
              <Link className='text-primary' to={'/'}>
                5S.rs
              </Link>
              . Sva prava zadržana.
            </p>
          </div>
        </div>
      </div>
      <a href='#' className='btn btn-primary back-to-top'>
        <i className='fa fa-angle-double-up' />
      </a>
    </footer>
  );
};
export default NewFooter;
