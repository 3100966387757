import { Link } from 'react-router-dom';

const NewProduct = ({ product }) => {
  return (
    <div className='col-lg-3 col-md-4 col-sm-4 pb-1'>
      <div className='product-item bg-light mb-4'>
        <div className='product-img position-relative overflow-hidden'>
          <img className='img-fluid w-100' src={product.images[0]} alt='' />
          <div className='product-action'>
            <Link
              className='btn btn-outline-dark btn-square'
              to={`/product/${product._id}`}
            >
              <i className='fa fa-shopping-cart' />
            </Link>
          </div>
        </div>
        <div className='text-center py-4 text-truncate'>
          <Link
            className='h6 text-decoration-none'
            to={`/product/${product._id}`}
          >
            {product.name}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
