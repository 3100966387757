import { useEffect } from "react";

const ProductCarousel = () => {
  useEffect(() => {
    const elem = document.getElementById('firstSlide');
    elem.click();
  });
  return (
    <div className='container-fluid mb-3'>
      <div className='row px-xl-5'>
        <div className='col-lg-12'>
          <div
            id='header-carousel'
            className='carousel slide carousel-fade mb-30 mb-lg-0'
            data-bs-ride='carousel'
          >
            <ol className='carousel-indicators'>
              <li
                data-target='#header-carousel'
                data-slide-to='0'
                className='active'
              ></li>
              <li
                data-target='#header-carousel'
                data-slide-to='1'
                id='firstSlide'
              ></li>
              <li data-target='#header-carousel' data-slide-to='2'></li>
              <li data-target='#header-carousel' data-slide-to='3'></li>
              <li data-target='#header-carousel' data-slide-to='4'></li>
              <li data-target='#header-carousel' data-slide-to='5'></li>
              <li data-target='#header-carousel' data-slide-to='6'></li>
            </ol>
            <div className='carousel-inner'>
              <div
                className='carousel-item position-relative active'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-2.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <div
                className='carousel-item position-relative'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-1.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <div
                className='carousel-item position-relative'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-3.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>{' '}
              <div
                className='carousel-item position-relative'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-4.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>{' '}
              <div
                className='carousel-item position-relative'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-5.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>{' '}
              <div
                className='carousel-item position-relative'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-6.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>{' '}
              <div
                className='carousel-item position-relative'
                data-interval='1500'
                style={{ height: 430 }}
              >
                <img
                  className='position-absolute w-100 h-100'
                  src='img/carousel-7.jpg'
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
