import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCheck, FaEdit, FaTimes, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from '../../slices/usersApiSlice';

const AdminNewUserListScreen = () => {
  const { data: users, refetch, isLoading, error } = useGetUsersQuery();

  const [deleteUser] = useDeleteUserMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      try {
        await deleteUser(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };
  if (isLoading) return null;
  if (error) return null;
  return (
    <div className='container-fluid'>
      <div className='row px-xl-5'>
        <div className='col-lg-12 table-responsive'>
          <h2 className='section-title position-relative text-uppercase mb-4'>
            <span className='bg-secondary pr-3'>Korisnici</span>
          </h2>
        </div>
        <div className='col-lg-12 table-responsive mb-5'>
          <table className='table table-light table-borderless table-hover text-center mb-0'>
            <thead className='thead-dark'>
              <tr>
                <th>Ime korisnika</th>
                <th>Email</th>
                <th>Da li je admin</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='align-middle'>
              {users.map((user) => (
                <tr>
                  <td className='align-middle'>{user.name}</td>
                  <td className='align-middle'>
                    <Link to={`mailto:${user.email}`}>{user.email}</Link>
                  </td>
                  <td className='align-middle'>
                    {user.isAdmin ? (
                      <FaCheck style={{ color: 'green' }} />
                    ) : (
                      <FaTimes style={{ color: 'red' }} />
                    )}
                  </td>
                  <td className='align-middle'>
                    {!user.isAdmin && (
                      <>
                        <Link
                          to={`/admin/user/${user._id}/edit`}
                          style={{ marginRight: '10px' }}
                        >
                          <Button variant='light' className='btn-sm'>
                            <FaEdit />
                          </Button>
                        </Link>
                        <Button
                          variant='danger'
                          className='btn-sm'
                          onClick={() => deleteHandler(user._id)}
                        >
                          <FaTrash style={{ color: 'white' }} />
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminNewUserListScreen;
