import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addToCart, removeFromCart } from '../slices/cartSlice';

const CartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  // NOTE: no need for an async function here as we are not awaiting the
  // resolution of a Promise
  const addToCartHandler = (product, qty) => {
    dispatch(addToCart({ ...product, qty }));
  };

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate('/login?redirect=/checkout');
  };

  const hasItemsInCart = cartItems.length > 0;

  return (
    <div className='container-fluid'>
      <div className='row px-xl-5'>
        <div className='col-lg-12 table-responsive mb-5'>
          <table className='table table-light table-borderless table-hover text-center mb-0'>
            <thead className='thead-dark'>
              <tr>
                <th>Proizvod</th>
                <th>Količina</th>
                <th>Ukloni</th>
              </tr>
            </thead>
            <tbody className='align-middle'>
              {!hasItemsInCart && (
                <tr>
                  <td className='align-middle' colSpan={4}>
                    <h3>Korpa prazna.</h3>
                  </td>
                </tr>
              )}
              {cartItems.map((item) => (
                <tr key={item._id}>
                  <td className='align-middle'>
                    <img src={item.image} alt='' style={{ width: '50px' }} />{' '}
                    {item.name}
                  </td>
                  <td className='align-middle'>
                    <div
                      className='input-group quantity mx-auto'
                      style={{ width: '100px' }}
                    >
                      <div className='input-group-btn'>
                        <button
                          onClick={() => addToCartHandler(item, item.qty - 1)}
                          className='btn btn-sm btn-primary btn-minus'
                        >
                          <i className='fa fa-minus' />
                        </button>
                      </div>
                      <input
                        type='text'
                        className='form-control form-control-sm bg-secondary border-0 text-center'
                        value={item.qty}
                      />
                      <div className='input-group-btn'>
                        <button
                          onClick={() => addToCartHandler(item, item.qty + 1)}
                          className='btn btn-sm btn-primary btn-plus'
                        >
                          <i className='fa fa-plus' />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className='align-middle'>
                    <button
                      className='btn btn-sm btn-danger'
                      onClick={() => removeFromCartHandler(item._id)}
                    >
                      <i className='fa fa-times' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={checkoutHandler}
            className='btn btn-block btn-primary font-weight-bold my-3 py-3'
            disabled={!hasItemsInCart}
            style={{ width: 200, float: 'right' }}
          >
            Nastavi na kasu
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartScreen;
