import { Link } from 'react-router-dom';

// To do - update to be dynamic
const Breadcrumb = () => {
  return (
    <div className='container-fluid'>
      <div className='row px-xl-5'>
        <div className='col-12'>
          <nav className='breadcrumb bg-light mb-30'>
            <Link className='breadcrumb-item text-dark' to='/'>
              Početna
            </Link>
            <span className='breadcrumb-item active'>Proizvodi</span>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Breadcrumb;
