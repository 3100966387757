import React from 'react';

import { Link } from 'react-router-dom';
import subcategories from '../resources/subcategories.json';

const ProductSubcategoryScreen = () => {
  return (
    <>
      <div className='container-fluid'>
        <div className='row px-xl-5'>
          {/* Shop Product Start */}
          <div className='col-lg-12'>
            <h2 className='section-title position-relative text-uppercase'>
              <span className='bg-secondary pr-3'>Sve kategorije</span>
            </h2>
            <div className='row'>
              {subcategories['lockOut'].map((category, index) => {
                return (
                  <div
                    className='col-lg-3 col-md-4 col-sm-6 pb-1'
                    key={category.name + index}
                  >
                    <Link
                      className='text-decoration-none'
                      to={`/subcategory/${category.name}`}
                    >
                      <div className='cat-item d-flex align-items-center mb-4'>
                        <div
                          className='overflow-hidden'
                          style={{ width: 100, height: 100 }}
                        >
                          <img
                            className='img-fluid'
                            src={category.img}
                            alt=''
                          />
                        </div>
                        <div className='flex-fill pl-3'>
                          <h6>{category.displayName}</h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
              {subcategories['floor_markings'].map((category, index) => {
                return (
                  <div
                    className='col-lg-3 col-md-4 col-sm-6 pb-1'
                    key={category.name + index}
                  >
                    <Link
                      className='text-decoration-none'
                      to={`/subcategory/${category.name}`}
                    >
                      <div className='cat-item d-flex align-items-center mb-4'>
                        <div
                          className='overflow-hidden'
                          style={{ width: 100, height: 100 }}
                        >
                          <img
                            className='img-fluid'
                            src={category.img}
                            alt=''
                          />
                        </div>
                        <div className='flex-fill pl-3'>
                          <h6>{category.displayName}</h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}{' '}
              {subcategories['brochures'].map((category, index) => {
                return (
                  <div
                    className='col-lg-3 col-md-4 col-sm-6 pb-1'
                    key={category.name + index}
                  >
                    <Link
                      className='text-decoration-none'
                      to={`/subcategory/${category.name}`}
                    >
                      <div className='cat-item d-flex align-items-center mb-4'>
                        <div
                          className='overflow-hidden'
                          style={{ width: 100, height: 100 }}
                        >
                          <img
                            className='img-fluid'
                            src={category.img}
                            alt=''
                          />
                        </div>
                        <div className='flex-fill pl-3'>
                          <h6>{category.displayName}</h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSubcategoryScreen;
