import Categories from '../components/Categories';
import Featured from '../components/Featured';
import FeaturedProducts from '../components/FeaturedProducts';
import Partners from '../components/Partners';
import ProductCarousel from '../components/ProductCarousel';

const HomeScreen = () => {

  return (
    <>
      <ProductCarousel />
      <Featured />
      <Categories />
      <FeaturedProducts  />
      <Partners />
    </>
  );
};

export default HomeScreen;
