import React from 'react';
import { Link, useParams } from 'react-router-dom';
import subcategories from '../resources/subcategories.json';

const SubcategoryListScreen = () => {
  const { category } = useParams();

  return (
    <div className='container-fluid pt-5'>
      <div className='row px-xl-5 pb-3'>
        {subcategories[category].map((subcategory, index) => {
          return (
            <div
              className='col-lg-3 col-md-4 col-sm-6 pb-1'
              key={subcategory.name + index}
            >
              <Link
                className='text-decoration-none'
                to={`/subcategory/${subcategory.name.toLowerCase()}`}
              >
                <div className='cat-item d-flex align-items-center mb-4'>
                  <div
                    className='overflow-hidden'
                    style={{ width: 100, height: 100 }}
                  >
                    <img className='img-fluid' src={subcategory.img} alt='' />
                  </div>
                  <div className='flex-fill pl-3'>
                    <h6>{subcategory.displayName}</h6>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubcategoryListScreen;
