import { useEffect } from 'react';
import './style.css';

const FeaturedProducts = () => {
  useEffect(() => {
    var copy = document.querySelector('.logos-slide').cloneNode(true);
    document.querySelector('.logos').appendChild(copy);
  }, []);

  return (
    <div className='container-fluid pt-5 pb-3'>
      <h2 className='section-title position-relative text-uppercase mx-xl-5 mb-4'>
        <span className='bg-secondary pr-3'>Najnoviji proizvodi</span>
      </h2>
      <div className='row px-xl-5'>
        <div className='logos'>
          <div className='logos-slide'>
            <img src='img/IMG_8320.jpg' />
            <img src='img/IMG_8391.jpg' />
            <img src='img/IMG_8400.jpg' />
            <img src='img/IMG_8422.jpg' />
            <img src='img/IMG_8432.jpg' />
            <img src='img/IMG_8433.jpg' />
            <img src='img/IMG_8438.jpg' />
            <img src='img/IMG_8441.jpg' />
            <img src='img/IMG_8453.jpg' />
            <img src='img/IMG_8469.jpg' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeaturedProducts;
