import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormContainer from '../../components/FormContainer';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from '../../slices/productsApiSlice';

import categories from '../../resources/categories.json';
import subcategories from '../../resources/subcategories.json';

const ProductEditScreen = () => {
  const { id: productId } = useParams();

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [images, setImages] = useState([]);
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState('');
  const [published, setPublished] = useState(false);

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const [updateProduct, { isLoading: loadingUpdate }] =
    useUpdateProductMutation();

  const [uploadProductImage, { isLoading: loadingUpload }] =
    useUploadProductImageMutation();

  const navigate = useNavigate();

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    ['clean'], // remove formatting button
  ];

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateProduct({
        productId,
        name,
        price,
        images,
        brand,
        category,
        subcategory,
        description,
        countInStock,
        published,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Product updated');
      refetch();
      navigate('/admin/productlist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setImages(product.images);
      setBrand(product.brand);
      setCategory(product.category);
      setSubcategory(product.subcategory);
      setCountInStock(product.countInStock);
      setDescription(product.description);
      setPublished(product.published);
    }
  }, [product]);

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    const files = e.target.files;
    if (files.length !== 0) {
      for (const single_file of files) {
        formData.append('images', single_file);
      }
    }
    try {
      const res = await uploadProductImage(formData).unwrap();
      toast.success(res.message);
      setImages(res.images);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Nazad
      </Link>
      <FormContainer>
        <h1>Izmeni proizvod</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler} encType='multipart/form-data'>
            <Form.Group controlId='name'>
              <Form.Label>Ime proizvoda</Form.Label>
              <Form.Control
                type='name'
                placeholder='Unesite ime proizvoda'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Slika</Form.Label>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  margin: '0 auto',
                  maxWidth: 1000,
                }}
              >
                {images.map((image, index) => (
                  <div
                    style={{
                      flex: '1 1 100px',
                      margin: 10,
                    }}
                    key={index}
                  >
                    <img
                      style={{ maxWidth: '100%', height: 'auto' }}
                      src={image}
                      alt={image}
                    />
                  </div>
                ))}
              </div>
              <Form.Control
                label='Izaberite sliku'
                onChange={uploadFileHandler}
                type='file'
                multiple
              ></Form.Control>
              {loadingUpload && <Loader />}
            </Form.Group>

            <Form.Group controlId='brand'>
              <Form.Label>Sifra proizvoda</Form.Label>
              <Form.Control
                type='text'
                placeholder='Unesite sifru proizvoda'
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='category'>
              <Form.Label>Kategorija</Form.Label>
              <select
                className='custom-select'
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                value={category}
              >
                <option value=''>Odaberi kategoriju proizvoda</option>
                {categories.map((category, index) => (
                  <option key={category.name + index} value={category.name}>
                    {category.displayName}
                  </option>
                ))}
              </select>
            </Form.Group>
            {category && (
              <Form.Group controlId='category'>
                <Form.Label>Podkategodija</Form.Label>
                <select
                  className='custom-select'
                  onChange={(e) => {
                    setSubcategory(e.target.value);
                  }}
                  value={subcategory}
                >
                  <option value=''>Odaberi podkategoriju proizvoda</option>
                  {subcategories[category].map((subcategory, index) => (
                    <option
                      key={subcategory.name + index}
                      value={subcategory.name}
                    >
                      {subcategory.displayName}
                    </option>
                  ))}
                </select>
              </Form.Group>
            )}

            <Form.Group controlId='description'>
              <Form.Label>Opis</Form.Label>
              <ReactQuill
                theme='snow'
                value={description}
                onChange={setDescription}
                style={{
                  background: 'white',
                }}
                modules={{ toolbar: toolbarOptions }}
              />
            </Form.Group>
            <Form.Group
              controlId='published'
              style={{ display: 'flex', marginTop: 10 }}
            >
              <Form.Label>Da li je proizvod aktivan?</Form.Label>
              <Form.Control
                type='checkbox'
                checked={published}
                onChange={() => setPublished(!published)}
              ></Form.Control>
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Izmeni proizvod
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
