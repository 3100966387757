import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import NewFooter from './components/NewFooter';
import { logout } from './slices/authSlice';

import { ErrorBoundary } from 'react-error-boundary';
const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime) {
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        dispatch(logout());
      }
    }
  }, [dispatch]);

  function fallbackRender({ error, resetErrorBoundary }) {
    console.log('fallbackRender', error);
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
      <div role='alert'>
        <p>Something went wrong:</p>
        <pre style={{ color: 'red' }}>{error.message}</pre>
      </div>
    );
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <ToastContainer />
      <Header />
      <main>
        <Outlet />
      </main>
      <NewFooter />
    </ErrorBoundary>
  );
};

export default App;
