import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';

import { useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

const validPIB = (pib) => {
  // Check if the phone number is empty or contains only whitespac
  if (!pib || /^\s*$/.test(pib)) {
    return false;
  }
  // Check if the PIB is of the correct length and consists of valid numbers
  if (pib.length === 9) {
    // Initialize checksum variable
    let checksum = 10;

    // Iterate through the first 8 characters of the PIB
    for (let i = 0; i < 8; i++) {
      // Convert the character to an integer and update the checksum
      checksum = (checksum + parseInt(pib.charAt(i), 10)) % 10;
      checksum = ((checksum === 0 ? 10 : checksum) * 2) % 11;
    }

    // Calculate the final checksum
    checksum = (11 - checksum) % 10;

    // Check if the calculated checksum matches the last digit of the PIB
    return parseInt(pib.charAt(8), 10) === checksum;
  }

  // Return false if the PIB is invalid
  return false;
};

const validatePhoneNumber = (phoneNumber) => {
  // Remove any non-digit characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Regular expression to match the two formats
  const phoneRegex = /^(?:\+381)?\d{9}$/;

  // Check if the phone number matches the specified formats
  return phoneRegex.test(cleanedPhoneNumber);
};

export const validateEmail = (email) => {
  // Regular expression to match email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the specified format
  return emailRegex.test(email);
};

const validatePasswordAndRepassword = (password, repassword) => {
  // Check if both passwords are non-empty
  if (!password || !repassword) {
    return false;
  }

  // Check if the passwords match
  if (password !== repassword) {
    return false;
  }

  // Check if the password meets the following criteria:
  // At least 8 characters long
  // Contains at least one lowercase letter
  // Contains at least one uppercase letter
  // Contains at least one number
  // Contains at least one special character
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  // Check if the password matches the specified criteria
  if (!passwordRegex.test(password)) {
    return false;
  }

  return true; // Password and repassword are valid
};

const RegisterScreen = () => {
  const [name, setName] = useState('');
  const [pib, setPib] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validPIB(pib)) {
      toast.error('PIB - Poreski identifikacioni broj nije dobar');
    } else if (validatePhoneNumber(phone)) {
      toast.error('Broj telefona nije dobar');
    } else if (!validateEmail(email)) {
      toast.error('Email nije u dobrom formatu');
    } else if (!validatePasswordAndRepassword(password, confirmPassword)) {
      toast.error('Lozinke nisu u dobro formatu');
    } else {
      try {
        const res = await register({
          name,
          email,
          password,
          pib,
          phone,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <FormContainer>
      <h1>Registruj se</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className='my-2' controlId='pib'>
          <Form.Label>PIB - Poreski identifikacioni broj</Form.Label>
          <Form.Control
            name='pib'
            placeholder='PIB - Poreski identifikacioni broj'
            type='number'
            maxLength={9}
            value={pib}
            onChange={(e) => setPib(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className='my-2' controlId='name'>
          <Form.Label>Ime firme</Form.Label>
          <Form.Control
            type='name'
            placeholder='Ime firme'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className='my-2' controlId='email'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className='my-2' controlId='phone'>
          <Form.Label>Broj telefona</Form.Label>
          <Form.Control
            name='phone'
            placeholder='Telefon'
            type='number'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className='my-2' controlId='password'>
          <Form.Label>Lozinka</Form.Label>
          <Form.Control
            type='password'
            placeholder='Lozinka'
            value={password}
            s
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className='my-2' controlId='confirmPassword'>
          <Form.Label>Potvrdite lozinku</Form.Label>
          <Form.Control
            type='password'
            placeholder='Potvrdite lozinku'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button disabled={isLoading} type='submit' variant='primary'>
          Registruj se
        </Button>

        {isLoading && <Loader />}
      </Form>

      <Row className='py-3'>
        <Col>
          Vec imate nalog?{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Uloguj se
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;
